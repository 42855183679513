import { Box, Flex, Text } from "@chakra-ui/react";
import React, { useEffect, useMemo } from "react";
import { MdCurrencyRupee } from "react-icons/md";
import { IoPerson } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { getLocalStorageItem } from "../utils/LocalStorage";
import { map } from "lodash";
import dayjs from "dayjs";
import { useClientStore } from "../store/Client";
import { STATUS } from "../Constant";

export const Dashboard = () => {
    const navigate = useNavigate()
    const token = useMemo(() => {
        return getLocalStorageItem("accessToken")
    }, [])

    useEffect(() => {
        if (!token) {
            navigate("/login")
        }
    }, [navigate, token])


    const { getClientAction, getClientStatus, allClients } = useClientStore(s => ({
        getClientAction: s.getClientAction,
        getClientStatus: s.getClientStatus,
        allClients: s.allClients
    }))

    useEffect(() => {
        if ((getClientStatus || 1) === STATUS.NOT_STARTED) {
            getClientAction()
        }
    }, [allClients, getClientAction, getClientStatus])


    // const { dashboardAction, dashboardStatus, dashboard } = useZwitchStore(s => ({
    //     dashboardAction: s.dashboardAction,
    //     dashboardStatus: s.dashboardStatus,
    //     dashboard: s.dashboard
    // }))

    // useEffect(() => {
    //     if (!allContacts && (!getContactStatus || getContactStatus === STATUS.NOT_STARTED)) {
    //         getContactAction()
    //     }
    //     if ((dashboardStatus || 1) === STATUS.NOT_STARTED) {
    //         dashboardAction()
    //     }
    // }, [dashboardAction, dashboardStatus, allContacts, getContactAction, getContactStatus])

    return (
        <Box p={{ base: 2, md: 5 }} bg={"defaultDark"} h={"100%"} className="scrollBar" maxH={"100%"} overflowY={"scroll"}>
            <Flex flexWrap={"wrap"} gap={{ base: 3, lg: 6 }}>
                <DashItem icon={<IoPerson />} count={allClients?.length || 0} label={"Total Clients"} />
            </Flex>
            {false ?
                <ActivityFeed />
                :
                null
            }
        </Box>
    )
}

const DashItem = ({ icon, count, label }) => {
    return (
        <Box w={{ base: "98%", sm: "47%", md: "47%", lg: "28%", xl: "23%" }} px={3} py={2} border={"1px solid"} borderColor={"borderWhite"} borderRadius={10}>
            <Flex align={"center"} fontSize={18} fontWeight={"semibold"} color={"defaultWhite"} >
                {icon}
                <Text ml={2}>{count}</Text>
            </Flex>
            <Text ml={1} fontSize={16} color={"whiteAlpha.700"}>{label}</Text>
        </Box>
    )
}

const ActivityFeed = ({ data }) => {
    return (
        <Box bg={"cardDark"} mt={5} p={5}>
            <Flex align="center" justify={"space-between"} fontSize={16} fontWeight={"semibold"}>
                <Text color={"defaultWhite"}>Activity Feed</Text>
                {/* <Text color={"blue.300"}>Show Filter</Text> */}
            </Flex>

            {map(data, (tran) => (
                <Flex flexDir={{ base: "column", md: "row" }} color={"whiteAlpha.700"} mt={5} py={8} px={{ base: 2, lg: 6, xl: 12 }} mx={{ base: 0, lg: 10, xl: 16 }} bg={"subCardDark"}>
                    <Flex gap={{ base: 3, md: 1 }} flexDir={{ base: "row", md: "column" }} pb={{ base: 5, md: 0 }} w={{ base: "100%", md: "20%" }} borderRight={{ base: "none", md: "1px solid" }} borderBottom={{ base: "1px solid", md: "none" }} borderColor={"borderWhite"} align={"center"} justify={"center"}>
                        <Text fontSize={16} color={"defaultWhite"} fontWeight={"semibold"}>{dayjs(tran.date).format("DD MMMM YYYY")}</Text>
                        <Text>{dayjs(tran.date).format('dddd')}</Text>
                    </Flex>
                    <Flex flexDir={{ base: "column", md: "row" }} align={"center"} pt={{ base: 5, md: 0 }} px={{ base: 0, md: 5, lg: 8, xl: 14 }}>
                        {/* <Image w={"30px"} h={"30px"} src={"/assets/bank.png"} /> */}
                        <MdCurrencyRupee fontSize={32} color="red.300" />
                        <Box ml={{ base: 0, md: 5 }} pt={{ base: 5, md: 0 }}>
                            <Flex gap={1} align={"end"} fontSize={{ base: 12, md: 14 }}><Highlight val={tran.transCount} /> payouts worth <MdCurrencyRupee style={{ marginBottom: 2, marginRight: -2 }} color={"white"} fontSize={16} fontWeight={"bold"} /> <Highlight val={tran.transTotal} /> debited</Flex>
                        </Box>
                    </Flex>
                </Flex>
            ))}
        </Box>
    )
}

const Highlight = ({ val }) => {
    return (
        <Text color={"defaultWhite"}><strong>{val}</strong></Text>
    )
}