import React, { useEffect } from "react";
import { Box, Button, Flex, FormControl, Image, Input, Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, Text, VStack } from "@chakra-ui/react"
import { useState } from "react"
import { useLoginStore } from "../store/Login";
import { STATUS } from "../Constant";
import { getLocalStorageItem, setLocalStorageItem } from "../utils/LocalStorage";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

export const LoginUser = () => {
    const navigate = useNavigate()
    const [toggleRenewal, setToggleRenewal] = useState(null)

    useEffect(() => {
        const accessToken = getLocalStorageItem("accessToken")
        if (accessToken) {
            navigate("/")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const { loginUserAction, userLoginStatus, userLogin, resetStatus } = useLoginStore(s => ({
        loginUserAction: s.loginUserAction,
        userLoginStatus: s.userLoginStatus,
        userLogin: s.userLogin,
        resetStatus: s.resetStatus
    }))
    const [inputValue, setInputValue] = useState({ username: "", password: "" })

    const inputHandler = (name, val) => {
        setInputValue(pre => ({ ...pre, [name]: val }))
    }

    const submitLogin = (e) => {
        e.preventDefault()
        loginUserAction(inputValue)
    }

    useEffect(() => {
        if (userLoginStatus === STATUS.SUCCESS) {
            resetStatus()
            if (dayjs(userLogin?.expdate).format("YYYY-MM-DD") > dayjs().format("YYYY-MM-DD")) {
                setLocalStorageItem("accessToken", userLogin.apktoken)
                window.location.reload()
                navigate("/")
            }
            else{
                setToggleRenewal(true)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resetStatus, userLogin, userLoginStatus])

    return (
        <Flex w={"100vw"} h={"100vh"} justify={"center"} align={"center"} bgGradient={"linear-gradient(to bottom right, blue.50, blue.500)"}>
            <Flex w={"80%"} h={{ base: "90%", lg: "80%" }} borderRadius={20}>
                <Flex display={{ base: "none", lg: "initial" }} flexDir={"column"} w="50%" h={"100%"} bg={"blue.100"} borderLeftRadius={20} align={"center"} justify={"center"}>
                    <Image w={"100%"} h={"100%"} src={"/assets/logo.jpeg"} alt="Banner" borderLeftRadius={20} />
                </Flex>
                <Box w={{ base: "100%", lg: "50%" }} h={"100%"} align={"center"} bg={{ base: "white", lg: "gray.50" }} borderLeftRadius={{ base: 20, lg: 0 }} borderRightRadius={20} px={{ base: 10, xl: 20 }} py={{ base: 5, xl: 10 }} justify={"center"}>
                    <Flex flexDir={"column"} w={"100%"} h={"100%"} align={"start"} justify={"center"}>
                        <Flex w={"100%"} display={{ base: "initial", lg: "none" }} justify={"center"} h={"80px"}>
                            <Image mb={4} w={{ base: "70%", md: "40%" }} h={"80px"} src={"/assets/logo.jpeg"} alt="Banner" />
                        </Flex>
                        <Text w={"100%"} textAlign={"center"} fontSize={{ base: 20, lg: 26 }} fontWeight={"bold"}>Welcome Back!</Text>
                        <form style={{ height: "100%", width: "100%" }} onSubmit={submitLogin}>
                            <Text textAlign={"start"} mt={{ base: 3, lg: 10 }} fontSize={{ base: 14, md: 18 }} fontWeight={"semibold"}>Enter Registered Username & Password</Text>
                            <Text textAlign={"start"} fontSize={13}>Please enter correct registered details</Text>
                            <FormControl isRequired>
                                <Input
                                    mt={{ base: 5, sm: 10 }}
                                    size={{ base: "sm", sm: "md" }}
                                    type="text"
                                    fontSize={14}
                                    fontWeight={"semibold"}
                                    isRequired
                                    placeholder={"User Name"}
                                    focusBorderColor='blue.400'
                                    value={inputValue?.username}
                                    onChange={(e) => inputHandler("username", e.target.value)}
                                />
                            </FormControl>
                            <FormControl isRequired>
                                <Input
                                    mt={4}
                                    size={{ base: "sm", sm: "md" }}
                                    type="password"
                                    fontSize={14}
                                    fontWeight={"semibold"}
                                    isRequired
                                    placeholder={"Password"}
                                    focusBorderColor='blue.400'
                                    value={inputValue?.password}
                                    onChange={(e) => inputHandler("password", e.target.value)}
                                />
                            </FormControl>
                            <Button w={"100%"} size={{ base: "sm", sm: "md" }} mt={5} type={"submit"} isLoading={userLoginStatus === STATUS.FETCHING} colorScheme={"blue"} >LogIn</Button>
                        </form>
                    </Flex>
                </Box>
            </Flex>
            {toggleRenewal &&
                <Modal size={"3xl"} isOpen={true} closeOnOverlayClick={false}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader borderBottom={"1px solid"} borderColor={"gray.200"}>Renewal Notification</ModalHeader>
                        <ModalBody>
                            <VStack>
                                <Image mt={5} w={"25%"} src="/assets/expire.png" alt="WebSquare Software Pvt. Ltd." />
                                <Text my={5}>Your account is expired, Please renewal it by contacting WebSquare Software</Text>

                                <Flex my={5} justify={"space-between"} fontSize={13}>
                                    <Image w={"40%"} src="/assets/websquare.png" alt="WebSquare Software Pvt. Ltd." />
                                    <Box w={"50%"}>
                                        <Text>311,3rd Floor, Center Tower, Central Spine, Vidhyadhar Nagar, Jaipur-302023</Text>
                                        <Text>Call us :  +91 8058881888</Text>
                                        <Text>Email :  support@websquaresoftware.com</Text>
                                    </Box>
                                </Flex>
                            </VStack>
                        </ModalBody>
                    </ModalContent >
                </Modal>
            }
        </Flex >
    )
}