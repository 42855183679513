import { Box, Button, Flex, FormControl, FormLabel, HStack, IconButton, Input, Spinner, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tooltip, Tr } from "@chakra-ui/react";
import { map, orderBy, toUpper } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { STATUS } from "../../Constant";
import dayjs from "dayjs";
import { MdCurrencyRupee, MdLocalPrintshop } from "react-icons/md";
import { AddClientService } from "./AddClientService";
import { AddIcon, EditIcon } from "@chakra-ui/icons";
import { useClientStore } from "../../store/Client";
import { DeleteButton } from "../../Constant/DeleteButton";
import { useReactToPrint } from "react-to-print";

export const Service = () => {
    const [inputValue, setInputValue] = useState({ stardate: dayjs().format("YYYY-MM-DD"), enddate: dayjs().format("YYYY-MM-DD") })
    const [toggleModal, setToggleModal] = useState(null)

    const inputHandler = (name, val, special) => {
        if (special === true) {
            const value = val;
            const regex = /^[a-zA-Z0-9]*$/;
            if (regex.test(value)) {
                setInputValue(pre => ({ ...pre, [name]: toUpper(value) }))
            }
        }
        else {
            setInputValue(pre => ({ ...pre, [name]: val }))
        }
    }

    const { getServiceAction, getServiceStatus, allServices, deleteServiceAction, deleteServiceStatus, resetAddService } = useClientStore(s => ({
        getServiceAction: s.getServiceAction,
        getServiceStatus: s.getServiceStatus,
        allServices: s.allServices,
        deleteServiceAction: s.deleteServiceAction,
        deleteServiceStatus: s.deleteServiceStatus,
        resetAddService: s.resetAddService
    }))

    useEffect(() => {
        if ((getServiceStatus || 1) === STATUS.NOT_STARTED) {
            getServiceAction(inputValue)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getServiceAction, getServiceStatus])

    const getFilter = () => {
        getServiceAction(inputValue)
    }

    const deleteService = (id) => {
        deleteServiceAction({ id, action: "Delete" })
    }

    const [printProps, setPrintProps] = useState(null)
    const printRef = useRef(null);

    const handlePrintClick = (props) => {
        setPrintProps(props);
    };

    const handlePrint = useReactToPrint({
        content: () => printRef.current,
        pageStyle: `
        @page {
            size: landscape;
          }
        `,
    });

    useEffect(() => {
        if (printProps) {
            handlePrint();
        }
    }, [printProps, handlePrint]);

    return (
        <Box p={5} bg={"defaultDark"} h={"100%"}>
            <Flex my={5} w={"100%"} justify={"space-between"} align={"center"}>
                <Text fontSize={{ base: 18, md: 20 }} fontWeight={"semibold"} color={"defaultWhite"}>Service List</Text>
                <Button size={{ base: "xs", md: "sm" }} colorScheme="blue" leftIcon={<AddIcon />} onClick={() => setToggleModal([])}>New Service</Button>
            </Flex>
            <Box className="scrollBar" maxH={"90%"} overflowY={"scroll"}>
                <Flex my={2} justify={"space-between"} align={"end"}>
                    <HStack justify={"flex-start"} color={"white"} align={"end"}>
                        <FormControl w={"20%"}>
                            <FormLabel fontSize={13}>Start Date</FormLabel>
                            <Input size={"sm"} bg={"white"} color={"black"} fontWeight={"semibold"} type={"date"} value={inputValue?.stardate} borderColor={"gray.400"} onChange={(e) => inputHandler("stardate", e.target.value, false)} />
                        </FormControl>
                        <FormControl w={"20%"}>
                            <FormLabel fontSize={13}>End Date</FormLabel>
                            <Input size={"sm"} bg={"white"} color={"black"} fontWeight={"semibold"} type={"date"} value={inputValue?.enddate} borderColor={"gray.400"} onChange={(e) => inputHandler("enddate", e.target.value, false)} />
                        </FormControl>
                        <FormControl w={"20%"}>
                            <FormLabel fontSize={13}>Client</FormLabel>
                            <Input size={"sm"} bg={"white"} color={"black"} fontWeight={"semibold"} type={"text"} value={inputValue?.cname} borderColor={"gray.400"} onChange={(e) => inputHandler("cname", e.target.value, false)} />
                        </FormControl>
                        <FormControl w={"20%"}>
                            <FormLabel fontSize={13}>Vehicle No.</FormLabel>
                            <Input size={"sm"} bg={"white"} color={"black"} fontWeight={"semibold"} type={"text"} value={inputValue?.vehicalno} borderColor={"gray.400"} onChange={(e) => inputHandler("vehicalno", e.target.value, true)} />
                        </FormControl>
                        <Button size={"sm"} colorScheme="blue" onClick={getFilter}>Filter</Button>
                    </HStack>
                    <Button size={"sm"} colorScheme="blue" onClick={() => handlePrintClick(allServices)}><MdLocalPrintshop /></Button>
                </Flex>
                {getServiceStatus === STATUS.FETCHING ?
                    <Flex py={10} justify={"center"} w={"100%"} color={"whiteAlpha.800"}>
                        <Spinner />
                    </Flex>
                    :
                    <TableContainer mt={5}>
                        <Table size='sm' variant={"simple"}>
                            <Thead>
                                <Tr border={"1px solid"} borderColor={"whiteAlpha.500"}>
                                    <Th color={"#e3e3e3"}>Date</Th>
                                    <Th color={"#e3e3e3"}>Amount</Th>
                                    <Th color={"#e3e3e3"}>Client</Th>
                                    <Th color={"#e3e3e3"}>Mobile No</Th>
                                    <Th color={"#e3e3e3"}>Vehicle No.</Th>
                                    <Th color={"#e3e3e3"}>Vehicle Type</Th>
                                    <Th color={"#e3e3e3"}>Kilometers(KM)</Th>
                                    <Th color={"#e3e3e3"}>Description</Th>
                                    <Th color={"#e3e3e3"}>Action</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {allServices?.length ?
                                    map(orderBy(allServices, "cdate", "desc"), serv => (
                                        <Tr color={"whiteAlpha.800"} borderColor={"whiteAlpha.500"}>
                                            <Td>{dayjs(serv.cdate).format("DD-MMM-YYYY hh:mm A")}</Td>
                                            <Td><Flex align={"center"} fontWeight={"semibold"} gap={1}><MdCurrencyRupee />{serv.amount}</Flex></Td>
                                            <Td fontWeight={"semibold"}>{serv.client}</Td>
                                            <Td fontWeight={"semibold"}>{serv.mobile}</Td>
                                            <Td>{serv.vehicleno}</Td>
                                            <Td>{serv.vehicletype}</Td>
                                            <Td>{serv.km}</Td>
                                            <Td>{serv.des}</Td>
                                            <Td>
                                                <Tooltip placement="top" label="Edit">
                                                    <IconButton color={"blue.100"} _hover={{ color: "blue.700" }} size={"xs"} variant={"ghost"} icon={<EditIcon />} onClick={() => setToggleModal(serv)} />
                                                </Tooltip>
                                                <DeleteButton
                                                    description={"Are you sure? Do you want to delete Service?"}
                                                    confirm={() => deleteService(serv.id)}
                                                    status={deleteServiceStatus}
                                                    reset={resetAddService}
                                                />
                                            </Td>
                                        </Tr>
                                    ))
                                    :
                                    <Tr color={"whiteAlpha.800"} borderColor={"whiteAlpha.500"}>
                                        <Td textAlign={"center"} colSpan={9}>No Services Found</Td>
                                    </Tr>
                                }
                            </Tbody>
                        </Table>
                    </TableContainer>
                }

                <Box display={"none"}>
                    {printProps &&
                        <Box ref={printRef}>
                            <PrintTable data={printProps} setPrintProps={setPrintProps} />
                        </Box>
                    }
                </Box>
            </Box>
            {toggleModal && <AddClientService data={toggleModal} closeModal={() => setToggleModal(null)} />}
        </Box >
    )
}

const PrintTable = ({ data, setPrintProps }) => {

    useEffect(() => {
        return () => setPrintProps(null)
    }, [setPrintProps])

    return (
        <Box px={5} h={"100%"}>
            <TableContainer>
                <Table size='sm' variant={"simple"}>
                    <Thead>
                        <Tr border={"1px solid"} borderColor={"whiteAlpha.500"}>
                            <Th color={"#e3e3e3"}>Date</Th>
                            <Th color={"#e3e3e3"}>Amount</Th>
                            <Th color={"#e3e3e3"}>Client</Th>
                            <Th color={"#e3e3e3"}>Mobile No</Th>
                            <Th color={"#e3e3e3"}>Vehicle No.</Th>
                            <Th color={"#e3e3e3"}>Vehicle Type</Th>
                            <Th color={"#e3e3e3"}>KM</Th>
                            <Th color={"#e3e3e3"}>Description</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {data?.length ?
                            map(orderBy(data, "cdate", "asc"), serv => (
                                <Tr borderColor={"whiteAlpha.500"}>
                                    <Td>{dayjs(serv.cdate).format("DD-MMM-YYYY hh:mm A")}</Td>
                                    <Td><Flex align={"center"} fontWeight={"semibold"} gap={1}><MdCurrencyRupee />{serv.amount}</Flex></Td>
                                    <Td fontWeight={"semibold"}>{serv.client}</Td>
                                    <Td fontWeight={"semibold"}>{serv.mobile}</Td>
                                    <Td>{serv.vehicleno}</Td>
                                    <Td>{serv.vehicletype}</Td>
                                    <Td>{serv.km}</Td>
                                    <Td>{serv.des}</Td>
                                </Tr>
                            ))
                            :
                            <Tr borderColor={"whiteAlpha.500"}>
                                <Td textAlign={"center"} colSpan={9}>No Services Found</Td>
                            </Tr>
                        }
                    </Tbody>
                </Table>
            </TableContainer>
        </Box>
    )
}