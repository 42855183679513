import { Navigate, RouterProvider, createBrowserRouter } from "react-router-dom";
import { Dashboard } from "./Components/dashboard";
import { LoginUser } from "./Components/login";
import { MainLayout } from "./layout/MainLayout";
import { ClientList } from "./Components/Clients/ClientList";
import { ChangePassword } from "./Components/ChangePass";
import { Service } from "./Components/Service";
import { getLocalStorageItem } from "./utils/LocalStorage";
import { useEffect } from "react";
// import { useEffect } from "react";

function App() {

  useEffect(() => {
    const timer = setTimeout(() => {
      localStorage.clear();
      window.location.reload();
      <Navigate to="/login" />
    }, 1800000);
    return () => clearTimeout(timer);
  }, []);

  const routerConfig = [
    { path: "/", element: <MainLayout><Dashboard /></MainLayout>, authRequired: true },
    { path: "/login", element: <LoginUser /> },
    { path: "/client-list", element: <MainLayout><ClientList /></MainLayout>, authRequired: true },
    { path: "/service", element: <MainLayout><Service /></MainLayout>, authRequired: true },
    { path: "/change-password", element: <MainLayout><ChangePassword /></MainLayout>, authRequired: true },
  ]


  function isAuthenticated() {
    const accessToken1 = getLocalStorageItem("accessToken")
    return accessToken1
  }

  function createBrowserRouterWithAuth(routes) {
    return createBrowserRouter(
      routes.map(route => {
        if (route.authRequired) {
          return {
            ...route,
            element: isAuthenticated() ? route.element : <Navigate to="/login" />,
          };
        }
        return route;
      })
    );
  }
  const router = createBrowserRouterWithAuth(routerConfig);

  // const routerConfig = [
  //   { path: "/", element: <MainLayout><Dashboard /></MainLayout> },
  //   { path: "/login", element: <LoginUser /> },
  //   { path: "/client-list", element: <MainLayout><ClientList /></MainLayout> },
  //   // { path: "/create-contact", element: <MainLayout><CreateContact /></MainLayout> },
  //   { path: "/service", element: <MainLayout><Service /></MainLayout> },
  //   { path: "/change-password", element: <MainLayout><ChangePassword /></MainLayout> },
  // ]
  // const router = createBrowserRouter(routerConfig);

  return (
    <html lang="en">
      <head>
        <link rel="icon" href="/public/assets/favicon.jpeg" />
        <title>Smart Drcr</title>
      </head>
      <body>
        <RouterProvider router={router} />
      </body>
    </html>
  );
}

export default App;
