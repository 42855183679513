import { Button, Checkbox, Flex, FormControl, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Table, Tbody, Td, Th, Thead, Tr, VStack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useClientStore } from "../../store/Client";
import { STATUS } from "../../Constant";
import { map, toUpper } from "lodash";
import dayjs from "dayjs";

export const AddClientService = ({ data, closeModal }) => {
    console.log(data);
    const [inputValue, setInputValue] = useState(
        data?.id ?
            {
                id: data.id,
                cdate: data.cdate,
                vno: data.vehicleno,
                amount: data.amount,
                clientid: data.clientid,
                clientvehid: data.clientvehid,
                des: data.des,
                km: data.km,
            }
            :
            { cdate: dayjs().format("YYYY-MM-DDTHH:mm") })

    const inputHandler = (name, val) => {
        setInputValue(pre => ({ ...pre, [name]: val }))
    }
    const { getClientVehicleAction, getClientVehicleStatus, clientVehicles, resetClientVehicle,
        addServiceAction, addServiceStatus, resetAddService
    } = useClientStore(s => ({
        getClientVehicleAction: s.getClientVehicleAction,
        getClientVehicleStatus: s.getClientVehicleStatus,
        clientVehicles: s.clientVehicles,
        resetClientVehicle: s.resetClientVehicle,
        addServiceAction: s.addServiceAction,
        addServiceStatus: s.addServiceStatus,
        resetAddService: s.resetAddService
    }))
    useEffect(() => {
        resetClientVehicle()
    }, [resetClientVehicle])

    useEffect(() => {
        if (data?.id) {
            getClientVehicleAction({ vno: data.vehicleno })
        }
    }, [data?.id, data.vehicleno, getClientVehicleAction])

    const getClient = () => {
        if (inputValue.vno) {
            getClientVehicleAction({ vno: inputValue.vno })
        }
    }

    const selectClient = (v) => {
        if (inputValue.clientid) {
            inputHandler("clientid", "")
            inputHandler("clientvehid", "")
        }
        else {
            inputHandler("clientid", v.clientid)
            inputHandler("clientvehid", v.id)
        }
    }
    console.log(inputValue);
    const submitData = (e) => {
        e.preventDefault()
        addServiceAction(data?.id ? { ...inputValue, action: "Edit" } : inputValue)
    }

    useEffect(() => {
        if (addServiceStatus === STATUS.SUCCESS) {
            resetAddService()
            resetClientVehicle()
            closeModal()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addServiceStatus, resetAddService])

    return (
        <Modal isOpen={data} onClose={closeModal} closeOnOverlayClick={false}>
            <ModalOverlay />
            <form onSubmit={submitData}>
                <ModalContent>
                    <ModalHeader borderBottom={"1px solid"} borderColor={"gray.200"}> {data?.id ? "Edit " : ""}Vehicle Service</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <VStack>
                            <InputForm name={"cdate"} label={"Date"} type={"datetime-local"} inputValue={inputValue} setInputValue={setInputValue} />
                            <Flex w={"100%"}>
                                <InputForm name={"vno"} label={"Vehicle No"} special={true} type={"text"} inputValue={inputValue} setInputValue={setInputValue} />
                                <Button size={"sm"} colorScheme="blue" onClick={getClient}>Get</Button>
                            </Flex>
                            {inputValue.vno && clientVehicles?.length && getClientVehicleStatus === STATUS.SUCCESS ?
                                <Table>
                                    <Thead>
                                        <Tr bg={"blue.600"}>
                                            <Th>Select</Th>
                                            <Th>Client</Th>
                                            <Th>Mobile No</Th>
                                            <Th>Vehicle Type</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {map(clientVehicles, v => {
                                            const checked = inputValue.clientid === parseInt(v.clientid) ? true : false
                                            return (
                                                <Tr>
                                                    <Td><Checkbox isChecked={checked} onChange={() => selectClient(v)} /></Td>
                                                    <Td>{v.client}</Td>
                                                    <Td>{v.vehicletype}</Td>
                                                    <Td>{v.vehicletype}</Td>
                                                </Tr>
                                            )
                                        })}
                                    </Tbody>
                                </Table>
                                :
                                null
                            }
                            <InputForm name={"km"} label={"Current KM"} type={"number"} inputValue={inputValue} setInputValue={setInputValue} />
                            <InputForm name={"amount"} label={"Amount"} type={"number"} inputValue={inputValue} setInputValue={setInputValue} />
                            <InputForm name={"des"} label={"Description"} type={"text"} inputValue={inputValue} setInputValue={setInputValue} />
                        </VStack>
                    </ModalBody>
                    <ModalFooter>
                        <Flex >
                            <Button size={"sm"} colorScheme={"blue"} variant={"outline"} onClick={closeModal}>Back</Button>
                            <Button size={"sm"} ml={4} colorScheme={"blue"} isDisabled={inputValue.clientid ? false : true} isLoading={addServiceStatus === STATUS.FETCHING} type="submit">{data?.id ? "Edit" : "Add"}</Button>
                        </Flex>
                    </ModalFooter>
                </ModalContent >
            </form>
        </Modal >
    )
}

const InputForm = ({ require, name, label, special, limit, type, inputValue, setInputValue }) => {

    const inputHandler = (val) => {
        if (special === true) {
            const value = val;
            const regex = /^[a-zA-Z0-9]*$/;
            if (regex.test(value)) {
                setInputValue(pre => ({ ...pre, [name]: toUpper(value) }))
            }
        }
        else if (type === "number") {
            const numericValue = val.replace(/\D/g, '');

            if (numericValue.length <= (limit || 10)) {
                setInputValue(pre => ({ ...pre, [name]: numericValue }))
            }
        }
        else {
            setInputValue(pre => ({ ...pre, [name]: val }))
        }
    }

    return (
        <FormControl isRequired={require ? true : false}>
            <Flex py={3} align={"center"}>
                <FormLabel w={"50%"} fontSize={13}>{label}&nbsp;:</FormLabel>
                <Input size={"sm"} type={type || "text"} variant={"flushed"} value={inputValue?.[name]} onChange={(e) => inputHandler(e.target.value)} />
            </Flex>
        </FormControl>
    )
}