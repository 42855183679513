import { URIS, apiJson } from ".";

export const apis = {
    loginApi: (payload) => apiJson.post(URIS.LOGIN, payload),
    changePassApi: (payload) => apiJson.post(URIS.CHANGE_PASS, payload),
    dashboardApi: (payload) => apiJson.get(URIS.DASHBOARD, payload),

    //Contact
    getClientApi: (payload) => apiJson.get(URIS.GET_CLIENTS, payload),
    addClientApi: (payload) => apiJson.post(URIS.ADD_CLIENT, payload),
    getClientVehicleApi: (payload) => apiJson.get(URIS.GET_CLIENT_VEHICLE, { params: payload }),
    addClientVehicleApi: (payload) => apiJson.post(URIS.ADD_CLIENT_VEHICLE, payload),
    deleteClientVehicleApi: (payload) => apiJson.post(URIS.ADD_CLIENT_VEHICLE, payload),
    getServiceApi: (payload) => apiJson.get(URIS.GET_SERVICE, { params: payload }),
    addServiceApi: (payload) => apiJson.post(URIS.ADD_SERVICE, payload),
}