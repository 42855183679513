import { create } from "zustand";
import { STATUS } from "../../Constant";
import { apis } from "../../services/apis/apis";
import { concat, filter, findIndex, map } from "lodash";
import { ErrorAlert, SuccessAlert } from "../../utils/Helper";

export const useClientStore = create((set, get) => ({
    resetAddStatus: async () => {
        set({
            addClientStatus: STATUS.NOT_STARTED,
            deleteClientStatus: STATUS.NOT_STARTED,
            addClientVehicleStatus: STATUS.NOT_STARTED,
        })
    },

    resetAddService: async () => {
        set({
            addServiceStatus: STATUS.NOT_STARTED,
            deleteServiceStatus: STATUS.NOT_STARTED,
        })
    },

    resetClientVehicle: async () => {
        set({
            getClientVehicleStatus: STATUS.NOT_STARTED,
        })
    },

    getClientAction: async (payload) => {
        set({ getClientStatus: STATUS.FETCHING });
        const data = await apis.getClientApi(payload);
        if (data?.data?.statuscode === 200) {
            set({
                allClients: data.data.data,
                getClientStatus: STATUS.SUCCESS
            })
        } else {
            set({ getClientStatus: STATUS.FAILED })
            ErrorAlert(data?.data?.responsemsg)
        }
    },

    addClientAction: async (payload) => {
        set({ addClientStatus: STATUS.FETCHING });
        const data = await apis.addClientApi(payload);
        if (data?.data?.statuscode === 200) {
            const prev = get().allClients
            const temp = data.data.data
            set({
                allClients: findIndex(prev, s => s.id === temp.id) !== -1 ?
                    map(prev, s => s.id === temp.id ? temp : s)
                    :
                    concat([temp], prev),
                addClientStatus: STATUS.SUCCESS
            })
            SuccessAlert(data.data.responsemsg)
        } else {
            set({ addClientStatus: STATUS.FAILED })
            ErrorAlert(data?.data?.responsemsg)
        }
    },

    deleteClientAction: async (payload) => {
        set({ deleteClientStatus: STATUS.FETCHING });
        const data = await apis.addClientApi(payload);
        if (data?.data?.statuscode === 200) {
            const prev = get().allClients
            set({
                allClients: filter(prev, v => v.id !== payload.id),
                deleteClientStatus: STATUS.SUCCESS
            })
            SuccessAlert(data.data.responsemsg)
        } else {
            set({ deleteClientStatus: STATUS.FAILED })
            ErrorAlert("Client is already exist in servies.")
        }
    },

    getClientVehicleAction: async (payload) => {
        set({ getClientVehicleStatus: STATUS.FETCHING });
        const data = await apis.getClientVehicleApi(payload);
        if (data?.data?.statuscode === 200) {
            set({
                clientVehicles: data.data.data,
                getClientVehicleStatus: STATUS.SUCCESS
            })
        } else {
            set({ getClientVehicleStatus: STATUS.FAILED })
            ErrorAlert(data?.data?.responsemsg)
        }
    },

    addClientVehicleAction: async (payload) => {
        set({ addClientVehicleStatus: STATUS.FETCHING });
        const data = await apis.addClientVehicleApi(payload);
        if (data?.data?.statuscode === 200) {
            const prev = get().clientVehicles
            const temp = data.data.data
            set({
                clientVehicles: concat(prev, [{ ...data.data.data, vehicleno: temp.vehicleNo, clientid: temp.clientId, vehicletype: temp.vehicleType }]),
                addClientVehicleStatus: STATUS.SUCCESS
            })
            SuccessAlert(data.data.responsemsg)
        } else {
            set({ addClientVehicleStatus: STATUS.FAILED })
            ErrorAlert(data?.data?.responsemsg)
        }
    },

    deleteClientVehicleAction: async (payload) => {
        set({ deleteClientVehicleStatus: STATUS.FETCHING });
        const data = await apis.deleteClientVehicleApi(payload);
        if (data?.data?.statuscode === 200) {
            const prev = get().clientVehicles
            set({
                clientVehicles: filter(prev, v => v.id !== payload.id),
                deleteClientVehicleStatus: STATUS.SUCCESS
            })
            SuccessAlert(data.data.responsemsg)
        } else {
            set({ deleteClientVehicleStatus: STATUS.FAILED })
            ErrorAlert(data?.data?.responsemsg)
        }
    },

    getServiceAction: async (payload) => {
        set({ getServiceStatus: STATUS.FETCHING });
        const data = await apis.getServiceApi(payload);
        if (data?.data?.statuscode === 200) {
            set({
                allServices: data.data.data,
                getServiceStatus: STATUS.SUCCESS
            })
        } else {
            set({ getServiceStatus: STATUS.FAILED })
            ErrorAlert(data?.data?.responsemsg)
        }
    },

    addServiceAction: async (payload) => {
        set({ addServiceStatus: STATUS.FETCHING });
        const data = await apis.addServiceApi(payload);
        if (data?.data?.statuscode === 200) {
            const prev = get().allServices
            const temp = data.data.data
            set({
                allServices: findIndex(prev, s => s.id === temp.id) !== -1 ?
                    map(prev, s => s.id === temp.id ? ({ ...temp, client: temp.cname, mobile: temp.mobileno, vehicleno: temp.vehicalno, vehicletype: temp.vehicaltype }) : s)
                    :
                    concat(prev, [{
                        ...temp, client: temp.cname, mobile: temp.mobileno, vehicleno: temp.vehicalno, vehicletype: temp.vehicaltype
                    }]),
                addServiceStatus: STATUS.SUCCESS
            })
            SuccessAlert(data.data.responsemsg)
        } else {
            set({ addServiceStatus: STATUS.FAILED })
            ErrorAlert(data?.data?.responsemsg)
        }
    },

    deleteServiceAction: async (payload) => {
        set({ deleteServiceStatus: STATUS.FETCHING });
        const data = await apis.addServiceApi(payload);
        if (data?.data?.statuscode === 200) {
            const prev = get().allServices
            set({
                allServices: filter(prev, v => v.id !== payload.id),
                deleteServiceStatus: STATUS.SUCCESS
            })
            SuccessAlert("Delete Successfully")
        } else {
            set({ deleteServiceStatus: STATUS.FAILED })
            ErrorAlert(data?.data?.responsemsg)
        }
    },
}))