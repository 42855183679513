import { AddIcon, PhoneIcon } from "@chakra-ui/icons"
import { Box, Button, Flex, FormControl, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Select, Spinner, Tag, Text, VStack } from "@chakra-ui/react"
import { map, toUpper } from "lodash"
import React, { useEffect, useState } from "react"
import { DeleteButton } from "../../Constant/DeleteButton";
import { useClientStore } from "../../store/Client";
import { STATUS } from "../../Constant";

export const VehicleRegistration = ({ client, closeModal }) => {
    const [addFund, setAddFund] = useState(null)
    const [inputValue, setInputValue] = useState({})

    const inputHandler = (name, val) => {
        setInputValue(pre => ({ ...pre, [name]: val }))
    }

    const { getClientVehicleAction, getClientVehicleStatus, clientVehicles,
        addClientVehicleAction, addClientVehicleStatus, resetAddStatus,
        deleteClientVehicleAction, deleteClientVehicleStatus,
    } = useClientStore(s => ({
        getClientVehicleAction: s.getClientVehicleAction,
        getClientVehicleStatus: s.getClientVehicleStatus,
        clientVehicles: s.clientVehicles,
        addClientVehicleAction: s.addClientVehicleAction,
        addClientVehicleStatus: s.addClientVehicleStatus,
        resetAddStatus: s.resetAddStatus,
        deleteClientVehicleAction: s.deleteClientVehicleAction,
        deleteClientVehicleStatus: s.deleteClientVehicleStatus,
    }))

    useEffect(() => {
        getClientVehicleAction({ clientid: client.id })
    }, [client.id, getClientVehicleAction])

    const addVehicle = (e) => {
        e.preventDefault()
        addClientVehicleAction({ ...inputValue, clientid: client.id });
    }

    const deleteVehicle = (id) => {
        deleteClientVehicleAction({ id })
    }

    useEffect(() => {
        if (addClientVehicleStatus === STATUS.SUCCESS) {
            resetAddStatus()
            setInputValue({ vehitype: "", vehino: "" })
            setAddFund(null)
        }
    }, [addClientVehicleStatus, closeModal, resetAddStatus])

    return (
        <Modal isOpen={client} onClose={closeModal}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader borderBottom={"1px solid"} borderColor={"gray.200"}>
                    {/* {getClientVehicleStatus === STATUS.FETCHING ?
                        <Flex w={"100%"} my={5}>
                            <Spinner />
                        </Flex>
                        : */}
                    <Flex borderRadius={10} align={"center"}>
                        <Box>
                            <Flex align="center">
                                <Text fontSize={16} fontWeight={"semibold"} color={"gray.900"}>{client.cname}</Text>
                                <Tag ml={10} size={"sm"} fontSize={10}>{"Customer"}</Tag>
                            </Flex>
                            <Flex mt={2} fontSize={12} color={"gray.500"}>
                                <Flex align={"center"}>
                                    <PhoneIcon />
                                    <Text ml={2}>{client.mobileNo}</Text>
                                </Flex>
                            </Flex>
                        </Box>
                    </Flex>
                    {/* } */}
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {addFund ?
                        <Flex my={5} w={"100%"} justify={"space-between"}>
                            <Text fontSize={12} fontWeight={"semibold"}>Add Vehicle</Text>
                            <Button size={"xs"} colorScheme="blue" leftIcon={<AddIcon />} variant={"outline"} onClick={() => setAddFund(false)}>Don't Add Vehicle?</Button>
                        </Flex>
                        :
                        <Flex my={5} w={"100%"} justify={"space-between"}>
                            <Text fontSize={12} fontWeight={"semibold"}>Vehicles</Text>
                            <Button size={"xs"} colorScheme="blue" leftIcon={<AddIcon />} variant={"outline"} onClick={() => setAddFund(true)}>Add Vehicle</Button>
                        </Flex>
                    }
                    {addFund ?
                        <form onSubmit={addVehicle}>
                            <VStack>
                                <InputForm name={"vehino"} label={"Vehicle Registration No."} type={"text"} special={true} inputValue={inputValue} setInputValue={setInputValue} />
                                <FormControl isRequired>
                                    <Flex py={3} align={"center"}>
                                        <FormLabel w={"65%"} fontSize={13}>Vehicle Type&nbsp;:</FormLabel>
                                        <Select variant={"flushed"} value={inputValue?.vehitype} placeholder="Select Vehicle Type" onChange={(e) => inputHandler("vehitype", e.target.value)}>
                                            <option style={{ background: "#111739", color: "rgba(255, 255, 255, 0.64)" }} value={"Bike"}>Bike</option>
                                            <option style={{ background: "#111739", color: "rgba(255, 255, 255, 0.64)" }} value={"Scooty"}>Scooty</option>
                                            <option style={{ background: "#111739", color: "rgba(255, 255, 255, 0.64)" }} value={"Car"}>Car</option>
                                            <option style={{ background: "#111739", color: "rgba(255, 255, 255, 0.64)" }} value={"Auto"}>Auto</option>
                                            <option style={{ background: "#111739", color: "rgba(255, 255, 255, 0.64)" }} value={"Mini-Truck"}>Mini Truck</option>
                                            <option style={{ background: "#111739", color: "rgba(255, 255, 255, 0.64)" }} value={"Truck"}>Truck</option>
                                        </Select>
                                    </Flex>
                                </FormControl>
                                <Flex w={"100%"} mt={5} justifyContent={"space-between"}>
                                    <Button size={"sm"} colorScheme={"blue"} variant={"outline"} onClick={() => setAddFund(false)}>Back</Button>
                                    <Button size={"sm"} colorScheme={"blue"} type="submit" isLoading={addClientVehicleStatus === STATUS.FETCHING}>Add</Button>
                                </Flex>
                            </VStack>
                        </form>
                        :
                        <Box>
                            {getClientVehicleStatus === STATUS.FETCHING ?
                                <Flex justify={"center"} w={"100%"}>
                                    <Spinner />
                                </Flex>
                                :
                                <Box>
                                    {clientVehicles?.length ?
                                        map(clientVehicles, (veh, index) => (
                                            <Flex
                                                key={veh.id}
                                                mt={1}
                                                cursor={"pointer"}
                                                p={2}
                                                py={3}
                                                _hover={{ bg: "cardDark", color: "defaultWhite" }}
                                                borderRadius={10}
                                            >
                                                {/* <Flex w={"100%"}  p={3}> */}
                                                <Text w={"45%"} fontSize={13} fontWeight={"semibold"}>{veh.vehicleno}</Text>
                                                <Flex w={"45%"}>
                                                    <Tag size={"sm"} fontSize={10}>{veh.vehicletype}</Tag>
                                                </Flex>
                                                <Flex w={"10%"} justify={"flex-end"}>

                                                    <DeleteButton
                                                        description={"Are you sure? Do you want to delete Vehicle?"}
                                                        confirm={() => deleteVehicle(veh.id)}
                                                        status={deleteClientVehicleStatus}
                                                        reset={resetAddStatus}
                                                    />
                                                </Flex>
                                            </Flex>
                                        ))
                                        :
                                        <Flex justify={"center"} w={"100%"} my={5}>No Vehicle Found</Flex>
                                    }
                                </Box>
                            }
                        </Box>
                    }
                </ModalBody>
            </ModalContent>
        </Modal >
    )
}

const InputForm = ({ name, label, limit, type, special, inputValue, setInputValue, w, focus }) => {

    const inputHandler = (val) => {
        if (special === true) {
            const value = val;
            const regex = /^[a-zA-Z0-9]*$/;
            if (regex.test(value)) {
                setInputValue(pre => ({ ...pre, [name]: toUpper(value) }))
            }
        }
        else if (type === "number") {
            const numericValue = val.replace(/\D/g, '');

            if (numericValue.length <= (limit || 10)) {
                setInputValue(pre => ({ ...pre, [name]: numericValue }))
            }
        }
        else {
            setInputValue(pre => ({ ...pre, [name]: val }))
        }
    }

    return (
        <FormControl isRequired>
            <Flex py={3} align={"center"}>
                <FormLabel w={w || "65%"} fontSize={13}>{label}</FormLabel>
                <Input size={"sm"} autoFocus={focus} type={type || "text"} variant={"flushed"} value={inputValue?.[name]} onWheel={(e) => e.target.blur()} borderColor={"gray.400"} onChange={(e) => inputHandler(e.target.value)} />
            </Flex>
        </FormControl>
    )
}