import { Box } from "@chakra-ui/react";
import { map } from "lodash";
import React from "react";
import { SidebarItems } from "../Constant/SidebarItems";
import { Flex, Text } from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import { MdLogout } from "react-icons/md";
import { RiLockPasswordLine } from "react-icons/ri";
import { removeLocalStorageItem } from "../utils/LocalStorage";

export const Sidebar = ({ isOpen, onClose }) => {
    const navigate = useNavigate()
    const logout = () => {
        removeLocalStorageItem("accessToken")
        localStorage.clear();
        window.location.reload()
        navigate("/login")
    }

    return (
        <Flex flexDir={"column"} w={"100%"} h={"100%"} justifyContent={"space-between"} bg="defaultDark" borderRight={"1px solid"} borderColor={"borderWhite"}>
            <Box>
                {map(SidebarItems, item => (
                    <Box key={item.name} py={"9px"} px={1} color={"defaultWhite"}>
                        <Link to={item.href} onClick={isOpen ? onClose : null}>
                            <Flex px={2} py={"5px"}
                                align={"center"}
                                borderRadius={5} _
                                cursor={"pointer"}
                                // bg={item.href === router.pathname ? "green.400" : "none"}
                                // color={item.href === router.pathname ? "white" : "none"}
                                // onClick={() => menuRoutes(item.href)}
                                _hover={{ bg: "blue.500", color: "white" }}
                            >
                                <Text w={"20%"} fontSize={22}>{item.icon}</Text>
                                <Text w={"80%"} fontSize={15} fontWeight={"semibold"}>{item.name}</Text>
                            </Flex>
                        </Link>
                    </Box>
                ))}
            </Box>
            <Box>
                <Link to={"/change-password"}>
                    <Flex px={2} py={"5px"} my={2}
                        color={"defaultWhite"}
                        align={"center"}
                        borderRadius={5} _
                        cursor={"pointer"}
                        _hover={{ bg: "blue.500", color: "white" }}
                    >
                        <Text w={"20%"} fontSize={22}><RiLockPasswordLine /></Text>
                        <Text w={"80%"} fontSize={15} fontWeight={"semibold"}>Change Password</Text>
                    </Flex>
                </Link>
                {/* <Link to={"/change-mpin"}>
                    <Flex px={2} py={"5px"} my={2}
                        color={"defaultWhite"}
                        align={"center"}
                        borderRadius={5} _
                        cursor={"pointer"}
                        _hover={{ bg: "blue.500", color: "white" }}
                    >
                        <Text w={"20%"} fontSize={22}><TbPasswordMobilePhone /></Text>
                        <Text w={"80%"} fontSize={15} fontWeight={"semibold"}>Change MPin</Text>
                    </Flex>
                </Link> */}
                <Flex px={2} py={"5px"} my={2}
                    color={"defaultWhite"}
                    align={"center"}
                    borderRadius={5} _
                    cursor={"pointer"}
                    _hover={{ bg: "blue.500", color: "white" }}
                >
                    <Text w={"20%"} fontSize={22}><MdLogout /></Text>
                    <Text w={"80%"} fontSize={15} fontWeight={"semibold"} onClick={logout}>Logout</Text>
                </Flex>
            </Box>
        </Flex>
    )
}