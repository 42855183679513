import { create } from "zustand";
import { STATUS } from "../../Constant";
import { apis } from "../../services/apis/apis";
import { ErrorAlert, SuccessAlert } from "../../utils/Helper";

export const useLoginStore = create((set, get) => ({
    resetStatus: async () => {
        set({
            userLoginStatus: STATUS.NOT_STARTED,
        })
    },

    resetPassStatus: async () => {
        set({
            changePassStatus: STATUS.NOT_STARTED,
        })
    },

    loginUserAction: async (payload) => {
        set({ userLoginStatus: STATUS.FETCHING });
        const { data } = await apis.loginApi(payload);
        if (data.statuscode === 200) {
            set({
                userLogin: data,
                userLoginStatus: STATUS.SUCCESS
            })
            SuccessAlert("User Login Successfully")
        } else {
            set({ userLoginStatus: STATUS.FAILED })
            ErrorAlert(data.responsemsg)
        }
    },

    changePassAction: async (payload) => {
        set({ changePassStatus: STATUS.FETCHING });
        const { data } = await apis.changePassApi(payload);
        if (data.statuscode === 200) {
            set({
                changePass: data,
                changePassStatus: STATUS.SUCCESS
            })
            SuccessAlert(data.message)
        } else {
            set({ changePassStatus: STATUS.FAILED })
            ErrorAlert(data.message)
        }
    },
}))