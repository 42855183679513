import { AddIcon, EditIcon, PhoneIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, FormControl, FormLabel, IconButton, Input, Spinner, Text, Tooltip } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { VehicleRegistration } from "./VehicleRegistration";
import { useClientStore } from "../../store/Client";
import { STATUS } from "../../Constant";
import { CreateClient } from "./CreateClient";
import { map } from "lodash";
import { RiMapPin2Fill } from "react-icons/ri";
import { DeleteButton } from "../../Constant/DeleteButton";
import { GrPowerReset } from "react-icons/gr";

export const ClientList = () => {
    const [toggleModal, setToggleModal] = useState(null)
    const [toggleRegistration, setToggleRegistration] = useState(null)
    const { getClientAction, getClientStatus, allClients, deleteClientAction, deleteClientStatus, resetAddStatus } = useClientStore(s => ({
        getClientAction: s.getClientAction,
        getClientStatus: s.getClientStatus,
        allClients: s.allClients,
        deleteClientAction: s.deleteClientAction,
        deleteClientStatus: s.deleteClientStatus,
        resetAddStatus: s.resetAddStatus
    }))

    useEffect(() => {
        if ((getClientStatus || 1) === STATUS.NOT_STARTED) {
            getClientAction()
        }
    }, [allClients, getClientAction, getClientStatus])

    const deleteClient = (id) => {
        deleteClientAction({ id, action: "Delete" })
    }

    const [inputValue, setInputValue] = useState({})
    const [filteredData, setFilteredData] = useState()

    const inputHandler = (name, val) => {
        setInputValue(pre => ({ ...pre, [name]: val }))
    }

    const filterClient = () => {
        setFilteredData(allClients.filter((client) =>
            client?.cname?.toLowerCase().includes(inputValue?.search?.toLowerCase())
        ))
    }

    const resetData = () => {
        setInputValue({ search: "" })
        setFilteredData(allClients)
    }

    console.log(filteredData);

    return (
        <Box p={5} bg={"defaultDark"} h={"100%"} className="scrollBar" maxH={"100%"} overflowY={"scroll"}>
            <Flex my={5} w={"100%"} justify={"space-between"} align={"center"}>
                <Text fontSize={{ base: 18, md: 20 }} fontWeight={"semibold"} color={"defaultWhite"}>Client List</Text>
                <Button size={{ base: "xs", md: "sm" }} colorScheme="blue" leftIcon={<AddIcon />} onClick={() => setToggleRegistration([])}>New Contact</Button>
            </Flex>
            <Flex color={"white"} align={"end"}>
                <FormControl w={"20%"} isRequired={require ? true : false}>
                    {/* <Flex py={3} align={"center"}> */}
                    <FormLabel w={"50%"} fontSize={13}>Search Client</FormLabel>
                    <Input size={"sm"} type={"text"} value={inputValue?.search} placeholder="Name" onChange={(e) => inputHandler("search", e.target.value)} />
                    {/* </Flex> */}
                </FormControl>
                <Button ml={3} size={{ base: "xs", md: "sm" }} colorScheme="blue" onClick={filterClient}>Get</Button>
                <Button ml={3} size={{ base: "xs", md: "sm" }} leftIcon={<GrPowerReset />} onClick={resetData}>reset</Button>
            </Flex>
            {getClientStatus === STATUS.FETCHING ?
                <Flex w={"100%"} justify={"center"} my={5}>
                    <Spinner />
                </Flex>
                :
                filteredData?.length ?
                    map(filteredData, client => (
                        <Flex my={3} key={client.id} _hover={{ bg: "lightDark" }} px={4} py={2} borderRadius={10} align={"center"} cursor={"pointer"} border={"1px solid"} borderColor={"borderWhite"}>
                            <Box w={"70%"} onClick={() => setToggleModal(client)} >
                                <Text fontSize={16} fontWeight={"semibold"} color={"defaultWhite"}>{client.cname}</Text>
                                <Flex flexDir={{ base: "column", md: "row" }} mt={2} fontSize={12} color={"whiteAlpha.700"}>
                                    <Flex align={"center"} w={"30%"} pr={4}>
                                        <PhoneIcon />
                                        <Text ml={2}>{client.mobileNo}</Text>
                                    </Flex>
                                    <Flex align={"center"} w={"50%"}>
                                        <RiMapPin2Fill fontSize={16} />
                                        <Text ml={2}>{client.address}</Text>
                                    </Flex>
                                </Flex>
                            </Box>
                            <Flex w={"30%"} justify="flex-end">
                                <Tooltip placement="top" label="Edit">
                                    <IconButton color={"blue.100"} _hover={{ color: "blue.700" }} size={"xs"} variant={"ghost"} icon={<EditIcon />} onClick={() => setToggleRegistration(client)} />
                                </Tooltip>
                                <DeleteButton
                                    description={"Are you sure? Do you want to delete Vehicle?"}
                                    confirm={() => deleteClient(client.id)}
                                    status={deleteClientStatus}
                                    reset={resetAddStatus}
                                />
                            </Flex>
                        </Flex>
                    ))
                    :
                    <Flex w={"100%"} justify={"center"} fontWeight={"semibold"} my={10} color={"white"}>
                        <Text>No Client Found</Text>
                    </Flex>
            }
            {toggleRegistration && <CreateClient client={toggleRegistration} closeModal={() => setToggleRegistration(null)} />}
            {toggleModal && <VehicleRegistration client={toggleModal} closeModal={() => setToggleModal(null)} />}
        </Box>
    )
}