import { Button, Flex, FormControl, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Textarea, VStack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useClientStore } from "../../store/Client";
import { STATUS } from "../../Constant";

export const CreateClient = ({ client, closeModal }) => {

    const [inputValue, setInputValue] = useState(client?.id ?
        {
            id: client.id,
            cname: client.cname,
            address: client.address,
            mobile: client.mobileNo,
            action: "Edit"
        }
        :
        {}
    )

    const inputHandler = (name, val) => {
        setInputValue(pre => ({ ...pre, [name]: val }))
    }
    const { addClientAction, addClientStatus, resetAddStatus } = useClientStore(s => ({
        addClientAction: s.addClientAction,
        addClientStatus: s.addClientStatus,
        resetAddStatus: s.resetAddStatus
    }))

    const submitData = (e) => {
        e.preventDefault()
        addClientAction(inputValue)
    }

    useEffect(() => {
        if (addClientStatus === STATUS.SUCCESS) {
            resetAddStatus()
            closeModal()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addClientStatus, resetAddStatus])

    return (
        <Modal isOpen={client} onClose={closeModal}>
            <ModalOverlay />
            <form onSubmit={submitData}>
                <ModalContent>
                    <ModalHeader borderBottom={"1px solid"} borderColor={"gray.200"}>Client Registration</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <VStack>
                            <InputForm name={"cname"} label={"Name"} type={"text"} inputValue={inputValue} setInputValue={setInputValue} />
                            <InputForm name={"mobile"} label={"Mobile No."} type={"number"} inputValue={inputValue} setInputValue={setInputValue} />
                            <FormControl>
                                <Flex py={3} align={"center"}>
                                    <FormLabel w={"50%"} fontSize={13}>Address&nbsp;:</FormLabel>
                                    <Textarea size={"sm"} type={"text"} variant={"flushed"} value={inputValue?.address} onChange={(e) => inputHandler("address", e.target.value)} />
                                </Flex>
                            </FormControl>
                        </VStack>
                    </ModalBody>
                    <ModalFooter>
                        <Flex >
                            <Button size={"sm"} colorScheme={"blue"} variant={"outline"} onClick={closeModal}>Back</Button>
                            <Button size={"sm"} ml={4} colorScheme={"blue"} isLoading={addClientStatus === STATUS.FETCHING} type="submit">Add</Button>
                        </Flex>
                    </ModalFooter>
                </ModalContent >
            </form>
        </Modal >
    )
}

const InputForm = ({ require, name, label, limit, type, inputValue, setInputValue }) => {

    const inputHandler = (val) => {
        if (type === "number") {
            const numericValue = val.replace(/\D/g, '');

            if (numericValue.length <= (limit || 10)) {
                setInputValue(pre => ({ ...pre, [name]: numericValue }))
            }
        }
        else {
            setInputValue(pre => ({ ...pre, [name]: val }))
        }
    }

    return (
        <FormControl isRequired={require ? true : false}>
            <Flex py={3} align={"center"}>
                <FormLabel w={"50%"} fontSize={13}>{label}&nbsp;:</FormLabel>
                <Input size={"sm"} type={type || "text"} variant={"flushed"} value={inputValue?.[name]} onChange={(e) => inputHandler(e.target.value)} />
            </Flex>
        </FormControl>
    )
}