import { HamburgerIcon } from "@chakra-ui/icons"
import { Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerOverlay, Flex, IconButton, Image } from "@chakra-ui/react"
import { Sidebar } from "./Sidebar"
import { useState } from "react"

export const Header = () => {
    const [toggle, setToggle] = useState(null)
    return (
        <>
            <Flex p={2} align={"center"} justify={"space-between"} borderBottom={"1px solid"} borderColor={"borderWhite"} color={"white"} bg={"defaultDark"}>
                <Flex align={"center"} p={1}>
                    <Image h="60px" w="160px" src="/assets/logo11.png" alt="Payout" />
                </Flex>
                <IconButton display={{ base: "initial", md: "none" }} icon={<HamburgerIcon />} onClick={() => setToggle([])} />
            </Flex>
            {toggle && <SidebarDrawer isOpen={toggle} onClose={() => setToggle(null)} />}
        </>
    )
}
const SidebarDrawer = ({ isOpen, onClose }) => {
    return (
        <Drawer
            isOpen={isOpen}
            placement='right'
            onClose={onClose}
        >
            <DrawerOverlay />
            <DrawerContent bg={"defaultDark"}>
                <DrawerCloseButton color={"defaultWhite"} />
                <DrawerBody>
                    <Sidebar isOpen={isOpen} onClose={onClose} />
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    )
}