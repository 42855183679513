import { TbNewSection } from "react-icons/tb";
import { AiOutlineTransaction } from "react-icons/ai";
import { MdDashboard } from "react-icons/md";

export const SidebarItems = [
    {
        name: "Dashboard",
        icon: <MdDashboard />,
        key: "",
        href: "/",
    },
    {
        name: "Client List",
        icon: <TbNewSection />,
        key: "client-list",
        href: "/client-list",
    },
    {
        name: "Service",
        icon: <AiOutlineTransaction />,
        key: "service",
        href: "/service",
    },
]