import { Button, Flex, FormControl, FormLabel, Input, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { STATUS } from "../../Constant";
import { useLoginStore } from "../../store/Login";
import { useNavigate } from "react-router-dom";

export const ChangePassword = () => {
    const navigate = useNavigate()
    const [inputValue, setInputValue] = useState({})

    const { changePassAction, changePassStatus, resetPassStatus } = useLoginStore(s => ({
        changePassAction: s.changePassAction,
        changePassStatus: s.changePassStatus,
        resetPassStatus: s.resetPassStatus
    }))

    const submitData = (e) => {
        e.preventDefault()
        changePassAction(inputValue)
    }

    useEffect(() => {
        if (changePassStatus === STATUS.SUCCESS) {
            resetPassStatus()
            setInputValue({
                oldpassword: "",
                newpassword: ""
            })
            navigate("/")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [changePassStatus, resetPassStatus])

    return (
        <Flex w={"100%"} h={"100%"} flexDir={"column"} align={"center"} bg={"defaultDark"} color={"defaultWhite"}>
            <Flex w={{ base: "95%", md: "70%", xl: "50%" }} flexDir={"column"} p={{ base: 2, md: 5 }} >
                <Text my={5} fontSize={20} fontWeight={"semibold"}>Change Password</Text>

                <form onSubmit={submitData}>
                    <InputForm name={"oldpassword"} label={"Old Password"} type={"text"} inputValue={inputValue} setInputValue={setInputValue} />
                    <InputForm name={"newpassword"} label={"New Password"} type={"text"} inputValue={inputValue} setInputValue={setInputValue} />
                    <Flex mt={5} justifyContent={"space-between"}>
                        <Button size={"sm"} colorScheme={"blue"} type="submit" isLoading={changePassStatus === STATUS.FETCHING}>Update</Button>
                    </Flex>
                </form>
            </Flex>
        </Flex >
    )
}

const InputForm = ({ name, label, limit, type, inputValue, setInputValue }) => {

    const inputHandler = (val) => {
        if (type === "number") {
            const numericValue = val.replace(/\D/g, '');

            if (numericValue.length <= (limit || 10)) {
                setInputValue(pre => ({ ...pre, [name]: numericValue }))
            }
        }
        else {
            setInputValue(pre => ({ ...pre, [name]: val }))
        }
    }

    return (
        <FormControl isRequired>
            <Flex py={3} align={"center"}>
                <FormLabel w={{ base: "70%", sm: "60%", lg: "45%", xl: "30%" }} fontSize={13}>{label}&nbsp;:</FormLabel>
                <Input size={"sm"} type={type || "text"} variant={"flushed"} value={inputValue?.[name]} onChange={(e) => inputHandler(e.target.value)} />
            </Flex>
        </FormControl>
    )
}